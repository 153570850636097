
import { computed, defineComponent, reactive, ref } from 'vue';
import { Jobs } from '../utils/jobs';

import axios, { AxiosPromise } from "axios";

axios.defaults.headers.post['Content-Type'] = "application/json; charset=utf-8";
axios.defaults.headers.post['X-Requested-With'] = "XMLHttpRequest";
axios.defaults.headers.get['Content-Type'] = "application/json; charset=utf-8";
axios.defaults.headers.get['X-Requested-With'] = "XMLHttpRequest";
// export default defineComponent({
//   name: 'HomeView',
//   components: {
//     HelloWorld,
//   },
// });

export default {
  name: 'Home',
  components: {
  },
  setup() {
    const title = ref('');
    const description = ref('');
    //const query = ref('Cool School after:2021-01-15 before:2022-01-30');
    const query = ref('');
    const channelId = ref('');
    const videoId = ref('');
    const searchType = ref('');
    const maxResults = ref(50);
    const date = ref('');
    const beforeDate = ref('');
    const queryButton = ref('Query');
    const downloadButton = ref('Generate CSV');
    const jobResults = ref(0);
    const dummyData = ref(false);
    const mlpShorts = ref(false);
    const searchShortsOnly = ref(false);
    const isLoading = ref(false);
    const errorMessage = ref('');
    const showFilter = ref(false);
    const videoLength = ref('00:00:00');
    const filteredItemsTotal = ref(-1);
    const showChannelColumns = ref(false);
    const enableGetPlaylists = ref(false);
    const searchFeed = ref(false);
    
    const state = reactive<{items: any[]}>({
      items: [
        // {
        //   id: 'abc',
        //   snippet: {
        //     title: 'test'
        //   },
        //   statistics: {
        //     viewCount: 1000
        //   }
        // }
      ]
    });


    let feedXml = 
`<?xml version="1.0" encoding="UTF-8"?>
<feed xmlns:yt="http://www.youtube.com/xml/schemas/2015" xmlns:media="http://search.yahoo.com/mrss/" xmlns="http://www.w3.org/2005/Atom">
 <link rel="self" href="http://www.youtube.com/feeds/videos.xml?channel_id=UCZ8_wntNdHu_VVJUItyupqA"/>
 <id>yt:channel:Z8_wntNdHu_VVJUItyupqA</id>
 <yt:channelId>Z8_wntNdHu_VVJUItyupqA</yt:channelId>
 <title>Bakugan Official Channel</title>
 <link rel="alternate" href="https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA"/>
 <author>
  <name>Bakugan Official Channel</name>
  <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
 </author>
 <published>2018-09-18T18:06:54+00:00</published>
 <entry>
  <id>yt:video:Evt1rB2BB-M</id>
  <yt:videoId>Evt1rB2BB-M</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 9: Nostalgia Battles Innovation - Battle Brawlers vs. 2023! 🔄</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=Evt1rB2BB-M"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-06T13:00:24+00:00</published>
  <updated>2024-04-08T18:04:55+00:00</updated>
  <media:group>
   <media:title>EPISODE 9: Nostalgia Battles Innovation - Battle Brawlers vs. 2023! 🔄</media:title>
   <media:content url="https://www.youtube.com/v/Evt1rB2BB-M?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i2.ytimg.com/vi/Evt1rB2BB-M/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="138" average="5.00" min="1" max="5"/>
    <media:statistics views="12401"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:rAijS-MslTc</id>
  <yt:videoId>rAijS-MslTc</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 8: Time-Travel with Bakugan - Battle Brawlers vs. 2023 Edition! ⌛</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=rAijS-MslTc"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-05T13:00:50+00:00</published>
  <updated>2024-04-07T23:48:57+00:00</updated>
  <media:group>
   <media:title>EPISODE 8: Time-Travel with Bakugan - Battle Brawlers vs. 2023 Edition! ⌛</media:title>
   <media:content url="https://www.youtube.com/v/rAijS-MslTc?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i3.ytimg.com/vi/rAijS-MslTc/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="87" average="5.00" min="1" max="5"/>
    <media:statistics views="5946"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:BXvPsrwAFUs</id>
  <yt:videoId>BXvPsrwAFUs</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 7: From Classic to Current - Battle Brawlers vs. 2023 Showdown! 🌐</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=BXvPsrwAFUs"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-04T13:00:08+00:00</published>
  <updated>2024-04-06T15:35:36+00:00</updated>
  <media:group>
   <media:title>EPISODE 7: From Classic to Current - Battle Brawlers vs. 2023 Showdown! 🌐</media:title>
   <media:content url="https://www.youtube.com/v/BXvPsrwAFUs?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i3.ytimg.com/vi/BXvPsrwAFUs/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="82" average="5.00" min="1" max="5"/>
    <media:statistics views="9014"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:vaEDrOCmCKQ</id>
  <yt:videoId>vaEDrOCmCKQ</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 6: Evolution of Brawls - Battle Brawlers vs. 2023 Extravaganza! 🎥</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=vaEDrOCmCKQ"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-03T13:00:50+00:00</published>
  <updated>2024-04-09T22:14:01+00:00</updated>
  <media:group>
   <media:title>EPISODE 6: Evolution of Brawls - Battle Brawlers vs. 2023 Extravaganza! 🎥</media:title>
   <media:content url="https://www.youtube.com/v/vaEDrOCmCKQ?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i3.ytimg.com/vi/vaEDrOCmCKQ/hqdefault.jpg" width="480" height="360"/>
   <media:description>Join us in this exclusive &quot;EPISODE 1&quot; through &quot;EPISODE 10&quot; series, as we compare the beginnings of the classic Bakugan Battle Brawlers (2007) with the modern saga of Bakugan (2023)! 🌟 Witness the evolution of battles, characters, and animation across a decade and a half. Whether you're a seasoned brawler or a newcomer to the Bakugan universe, this ten-part comparison series is a nostalgic journey through the ages. Subscribe now and embark on an adventure that spans the rich history of Bakugan! 🔍🌈 

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="63" average="5.00" min="1" max="5"/>
    <media:statistics views="2775"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:ISBy6fNfLEk</id>
  <yt:videoId>ISBy6fNfLEk</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 5: Unveiling Bakugan Saga - Battle Brawlers vs. 2023 Edition! 🌈</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=ISBy6fNfLEk"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-02T13:00:15+00:00</published>
  <updated>2024-04-03T23:19:13+00:00</updated>
  <media:group>
   <media:title>EPISODE 5: Unveiling Bakugan Saga - Battle Brawlers vs. 2023 Edition! 🌈</media:title>
   <media:content url="https://www.youtube.com/v/ISBy6fNfLEk?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i2.ytimg.com/vi/ISBy6fNfLEk/hqdefault.jpg" width="480" height="360"/>
   <media:description>Join us in this exclusive &quot;EPISODE 1&quot; through &quot;EPISODE 10&quot; series, as we compare the beginnings of the classic Bakugan Battle Brawlers (2007) with the modern saga of Bakugan (2023)! 🌟 Witness the evolution of battles, characters, and animation across a decade and a half. Whether you're a seasoned brawler or a newcomer to the Bakugan universe, this ten-part comparison series is a nostalgic journey through the ages. Subscribe now and embark on an adventure that spans the rich history of Bakugan! 🔍🌈 #BakuganComparison #BattleBrawlersVs2023 #BakuganEvolution 🎬💫

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="57" average="5.00" min="1" max="5"/>
    <media:statistics views="3418"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:374yGo2hcfA</id>
  <yt:videoId>374yGo2hcfA</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Bakugan Brawlers Finale: Episodes 41-52 Marathon! 🏁</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=374yGo2hcfA"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-04-01T13:00:02+00:00</published>
  <updated>2024-04-03T09:08:06+00:00</updated>
  <media:group>
   <media:title>Bakugan Brawlers Finale: Episodes 41-52 Marathon! 🏁</media:title>
   <media:content url="https://www.youtube.com/v/374yGo2hcfA?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/374yGo2hcfA/hqdefault.jpg" width="480" height="360"/>
   <media:description>🌈 It's the grand finale! Experience the culmination of the Bakugan Brawlers series with episodes 41-50 in this epic marathon. Join Dan, Drago, and the brawlers as they face their ultimate challenges, confront powerful foes, and bring the saga to a breathtaking conclusion. Don't miss the emotional highs and lows in this unforgettable finale compilation!

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="145" average="5.00" min="1" max="5"/>
    <media:statistics views="8968"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:Fp9pVgCvPR4</id>
  <yt:videoId>Fp9pVgCvPR4</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Bakugan Brawlers Clash: Episodes 31-40 Marathon! 🌪️</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=Fp9pVgCvPR4"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-31T13:00:19+00:00</published>
  <updated>2024-04-26T09:49:52+00:00</updated>
  <media:group>
   <media:title>Bakugan Brawlers Clash: Episodes 31-40 Marathon! 🌪️</media:title>
   <media:content url="https://www.youtube.com/v/Fp9pVgCvPR4?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i3.ytimg.com/vi/Fp9pVgCvPR4/hqdefault.jpg" width="480" height="360"/>
   <media:description>🌐 The clash of Brawlers intensifies! Dive into the action-packed episodes 31-40 as the stakes rise, alliances are tested, and new adversaries emerge. Join Dan and his friends in this marathon and witness the thrilling battles that define the heart and soul of Bakugan Brawlers.

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="205" average="5.00" min="1" max="5"/>
    <media:statistics views="21388"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:CfjHRYY89BA</id>
  <yt:videoId>CfjHRYY89BA</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Epic Showdown: Drago vs. Nillious - Bakugan Battle of the Ages!</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=CfjHRYY89BA"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-30T13:00:15+00:00</published>
  <updated>2024-03-31T22:27:43+00:00</updated>
  <media:group>
   <media:title>Epic Showdown: Drago vs. Nillious - Bakugan Battle of the Ages!</media:title>
   <media:content url="https://www.youtube.com/v/CfjHRYY89BA?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/CfjHRYY89BA/hqdefault.jpg" width="480" height="360"/>
   <media:description>🚀 Get yourself prepared for the most anticipated Bakugan battle of all time! In an unprecedented face-off, Drago and Nillious, sworn enemies from different realms, square off for their FIRST-ever legendary brawl! ⚔️

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="93" average="5.00" min="1" max="5"/>
    <media:statistics views="8545"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:sy4atUQG_48</id>
  <yt:videoId>sy4atUQG_48</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Dan Kouzo's Inspiring Journey to a Bright Future | Bakugan Cartoon</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=sy4atUQG_48"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-29T13:00:55+00:00</published>
  <updated>2024-04-25T03:20:44+00:00</updated>
  <media:group>
   <media:title>Dan Kouzo's Inspiring Journey to a Bright Future | Bakugan Cartoon</media:title>
   <media:content url="https://www.youtube.com/v/sy4atUQG_48?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/sy4atUQG_48/hqdefault.jpg" width="480" height="360"/>
   <media:description>Join us in a moment of reflection and inspiration with the legendary Dan from Bakugan! In this uplifting video, we explore the profound wisdom of allowing your future to find you, as Dan shares a powerful and motivational quote that will resonate with brawlers and dreamers alike. 🌈✨

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="54" average="5.00" min="1" max="5"/>
    <media:statistics views="3286"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:4fwaUGqkOXo</id>
  <yt:videoId>4fwaUGqkOXo</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Drago Loses An ICONIC Battle!! | Bakugan Cartoon | Cartoons for Kids</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=4fwaUGqkOXo"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-28T13:00:03+00:00</published>
  <updated>2024-03-30T07:00:03+00:00</updated>
  <media:group>
   <media:title>Drago Loses An ICONIC Battle!! | Bakugan Cartoon | Cartoons for Kids</media:title>
   <media:content url="https://www.youtube.com/v/4fwaUGqkOXo?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i1.ytimg.com/vi/4fwaUGqkOXo/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="82" average="5.00" min="1" max="5"/>
    <media:statistics views="9128"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:G9KmjiMgseU</id>
  <yt:videoId>G9KmjiMgseU</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>The Dragon Clan Meets For The FIRST Time! | Bakugan | Cartoons for Kids</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=G9KmjiMgseU"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-26T14:01:00+00:00</published>
  <updated>2024-03-28T02:29:26+00:00</updated>
  <media:group>
   <media:title>The Dragon Clan Meets For The FIRST Time! | Bakugan | Cartoons for Kids</media:title>
   <media:content url="https://www.youtube.com/v/G9KmjiMgseU?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/G9KmjiMgseU/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="74" average="5.00" min="1" max="5"/>
    <media:statistics views="6468"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:kZ950qszRvQ</id>
  <yt:videoId>kZ950qszRvQ</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>EPISODE 4: Bakugan Through Time - Battle Brawlers vs. 2023! ⏳</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=kZ950qszRvQ"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-22T13:00:45+00:00</published>
  <updated>2024-04-28T08:55:15+00:00</updated>
  <media:group>
   <media:title>EPISODE 4: Bakugan Through Time - Battle Brawlers vs. 2023! ⏳</media:title>
   <media:content url="https://www.youtube.com/v/kZ950qszRvQ?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/kZ950qszRvQ/hqdefault.jpg" width="480" height="360"/>
   <media:description>Join us in this exclusive &quot;EPISODE 1&quot; through &quot;EPISODE 10&quot; series, as we compare the beginnings of the classic Bakugan Battle Brawlers (2007) with the modern saga of Bakugan (2023)! 🌟 Witness the evolution of battles, characters, and animation across a decade and a half. Whether you're a seasoned brawler or a newcomer to the Bakugan universe, this ten-part comparison series is a nostalgic journey through the ages. Subscribe now and embark on an adventure that spans the rich history of Bakugan! 🔍🌈 

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="99" average="5.00" min="1" max="5"/>
    <media:statistics views="4516"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:hFkXzERXbaM</id>
  <yt:videoId>hFkXzERXbaM</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Bakugan Bond: Unleashing the Power Within! How Do The Bakugan Get So BIG?! | Bakugan Cartoon</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=hFkXzERXbaM"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-19T13:00:43+00:00</published>
  <updated>2024-04-27T03:58:44+00:00</updated>
  <media:group>
   <media:title>Bakugan Bond: Unleashing the Power Within! How Do The Bakugan Get So BIG?! | Bakugan Cartoon</media:title>
   <media:content url="https://www.youtube.com/v/hFkXzERXbaM?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i1.ytimg.com/vi/hFkXzERXbaM/hqdefault.jpg" width="480" height="360"/>
   <media:description>🌟 Brace yourselves, Bakugan fans! 🌟 Join us on an epic journey as we explore the incredible phenomenon of Bakugan growing BIGGER after forming an unbreakable bond with their human partners! 🤝✨

In this jaw-dropping video, witness the magic unfold as Bakugan evolve and reach new heights, unlocking their true potential through the power of friendship and connection. 🌈💥 Get ready for some mind-blowing transformations and heartwarming moments that showcase the unspoken bond between Bakugan and their chosen brawlers.&quot;

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="31" average="5.00" min="1" max="5"/>
    <media:statistics views="3420"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:Op0K3HW-UH8</id>
  <yt:videoId>Op0K3HW-UH8</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Dan's Dilemma is Searching for the Perfect Hobby 🤔 | Bakugan Cartoon</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=Op0K3HW-UH8"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-15T13:00:31+00:00</published>
  <updated>2024-04-26T07:24:41+00:00</updated>
  <media:group>
   <media:title>Dan's Dilemma is Searching for the Perfect Hobby 🤔 | Bakugan Cartoon</media:title>
   <media:content url="https://www.youtube.com/v/Op0K3HW-UH8?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/Op0K3HW-UH8/hqdefault.jpg" width="480" height="360"/>
   <media:description>Get ready for a hilarious and heartwarming Bakugan family moment! Join us in this funny episode as Dan faces the ultimate dilemma – quitting all his hobbies and developing a new one! 😅🎭 Thanks mom!

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="57" average="5.00" min="1" max="5"/>
    <media:statistics views="3915"/>
   </media:community>
  </media:group>
 </entry>
 <entry>
  <id>yt:video:woF37j81Q9Y</id>
  <yt:videoId>woF37j81Q9Y</yt:videoId>
  <yt:channelId>UCZ8_wntNdHu_VVJUItyupqA</yt:channelId>
  <title>Bakugan Bond: Unleashing the Power Within! How Do The Bakugan Get So BIG?! | Bakugan Cartoon</title>
  <link rel="alternate" href="https://www.youtube.com/watch?v=woF37j81Q9Y"/>
  <author>
   <name>Bakugan Official Channel</name>
   <uri>https://www.youtube.com/channel/UCZ8_wntNdHu_VVJUItyupqA</uri>
  </author>
  <published>2024-03-08T14:00:46+00:00</published>
  <updated>2024-04-25T21:12:44+00:00</updated>
  <media:group>
   <media:title>Bakugan Bond: Unleashing the Power Within! How Do The Bakugan Get So BIG?! | Bakugan Cartoon</media:title>
   <media:content url="https://www.youtube.com/v/woF37j81Q9Y?version=3" type="application/x-shockwave-flash" width="640" height="390"/>
   <media:thumbnail url="https://i4.ytimg.com/vi/woF37j81Q9Y/hqdefault.jpg" width="480" height="360"/>
   <media:description>🌟 Brace yourselves, Bakugan fans! 🌟 Join us on an epic journey as we explore the incredible phenomenon of Bakugan growing BIGGER after forming an unbreakable bond with their human partners! 🤝✨

In this jaw-dropping video, witness the magic unfold as Bakugan evolve and reach new heights, unlocking their true potential through the power of friendship and connection. 🌈💥 Get ready for some mind-blowing transformations and heartwarming moments that showcase the unspoken bond between Bakugan and their chosen brawlers.

Get ready to brawl with the all-NEW Bakugan cartoon! Join the adventures of Dan Kouzo and the Misfit Clan as they join forces with alien creatures called Bakugan in epic battles for the fate of the world.

Watch tons of videos from the Bakugan cartoon:
https://www.youtube.com/playlist?list=PLTwahfEiohVvCKraTrMQpQc6bHRwwhbrE
Build your Bakugan toy collection:
https://www.youtube.com/playlist?list=PLTwahfEiohVs1mQadVGxx2s4j47Wu1rva

Learn more about the Bakugan universe: https://bakugan.com/en/home
Follow us to see what’s poppin’!
TikTok: https://tiktok.com/@bakugan
Instagram: https://instagram.com/bakugan/
Facebook: https://facebook.com/BakuganOfficial/

#Bakugan #Anime #CartoonsforKids</media:description>
   <media:community>
    <media:starRating count="69" average="5.00" min="1" max="5"/>
    <media:statistics views="5782"/>
   </media:community>
  </media:group>
 </entry>


 <entry>
   <yt:videoId>PZQMdha1wRY</yt:videoId>
  </entry>

  
 <entry>
   <yt:videoId>WMBfa0L6Y34</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>9faZiFJ7XbM</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>M02yvrWTvIk</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>6odUNPlZ3G0</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>PnlSI2DojAw</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>qXc8Ndqf0Eo</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>d4PTcdd4Q_o</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>MOPQ_-DGYNc</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>aNTCaD8Q5yw</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>smr9ovigSo4</yt:videoId>
  </entry>


  <entry>
   <yt:videoId>uRGNNWd_luw</yt:videoId>
  </entry>

  
</feed>`;

    setTimeout(() => {
      //state.items[0].snippet.title = 'nowhere';
      //state.items.push({id: 'a2', snippet: {title: 'and'}, statistics: {viewCount: 100}})
    }, 4000);

    let backendUrl = '';
    switch (process.env.NODE_ENV) {
      case 'development':
        backendUrl = 'http://localhost:3039/api';
        break;
      case 'production':
        backendUrl = 'https://youtube.driver-studios.com/api';
        break;
    }

    function convertISO8601ToReadable(duration: string): string  {

        const time_extractor = /^P([0-9]*D)?T([0-9]*H)?([0-9]*M)?([0-9]*S)?$/i;
        //const time_extractor = /^P([0-9]*D)?T(?:(\d+\.*\d*)H)?(?:(\d+\.*\d*)M)?(?:(\d+\.*\d*)S)?$/i;
        const extracted = time_extractor.exec(duration);
        if (extracted) {
            const days = parseInt(extracted[1], 10) || 0;
            let hours = parseInt(extracted[2], 10) || 0;
            const minutes = (parseInt(extracted[3], 10) || 0) + '';
            const seconds = (parseInt(extracted[4], 10) || 0) + '';
            
            hours = hours + days * 24;

            let hoursStr = hours + '';
            hoursStr = hoursStr.padStart(2, '0');
            const minutesStr = minutes.padStart(2, '0');
            const secondsStr = seconds.padStart(2, '0');

            return hoursStr + ':' + minutesStr + ':' + secondsStr;
            //''.padStart()
            //return (days * 24 * 3600 * 1000) + (hours * 3600 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
        }
        return '';
    }

   // let ms = convertISO8601ToReadable(null as any);

    // let seconds = ms / 1000;

    // let ts = new Date(ms);
    // let c = ts.toUTCString().match(/(\d\d:\d\d:\d\d)/)![0];
    
    //console.log(ms);

    let searchNextPageToken = '';
    let searchParams: any = {};
    let items: any[] = [];
    let filteredItems: any[] = [];
    let searchMLPShorts = false;
    let searchShorts = false;

    let job = Jobs.create(context => {

      let search = async (first = false) => {
        // setTimeout(() => {
        //   context.complete();
        // }, 1000);
        if (!first && !searchNextPageToken) {
          return context.complete();
        }

        console.log('jobResults.value, maxResults.value', jobResults.value, maxResults.value);
        if (jobResults.value >= maxResults.value || jobResults.value >= 5000) {
          return context.complete();
        }

        let data: any;
        data = {query: 'search', pageToken: searchNextPageToken, maxResults: maxResults.value, searchParams, dummyData: dummyData.value};
        if (searchMLPShorts) {
          data = {query: 'temp_mylittlepony_shorts', pageToken: searchNextPageToken, maxResults: maxResults.value, searchParams, dummyData: dummyData.value};
        }
        if (searchShorts) {
          data = {query: "search_shorts", pageToken: searchNextPageToken, maxResults: maxResults.value, searchParams, dummyData: dummyData.value};
        }
        // if (searchShorts) {
        //   data = {query: "search_lem", pageToken: searchNextPageToken, maxResults: maxResults.value, searchParams, dummyData: dummyData.value};
          
        // }
        if (enableGetPlaylists.value) {
          data = {query: "get_playlists", pageToken: searchNextPageToken, maxResults: maxResults.value, searchParams, dummyData: dummyData.value};
        }

        if (videoId.value) {
          
          data = {query: "video_ids", pageToken: searchNextPageToken, maxResults: maxResults.value, videoIds: videoId.value, dummyData: dummyData.value};
        }
        //console.log('search query');
        //console.log(data);
        let resp: any;
        try {
          resp = await axios(`${backendUrl}/job`, {withCredentials: false, method: 'POST', data: data});
        } catch (err: any) {
          context.pause();
          errorMessage.value = 'Error Message:<br>' + err.response.data.message + '<br>' + err.response.data.stack;
          return alert('Error encountered on backend. Aborting query.');
        }

        let youtubeData = resp.data.data;
        //context.commit('fetchItem', resp.data);
        //console.log(resp.data);
        //console.log('after')

        //items = items.concat(youtubeData.items);

        //state.items = state.items.concat(youtubeData.items);



        // for (let i = 0; i < youtubeData.items.length; i++) {
        //   let youtubeItem = youtubeData.items[i];
        //   let item: any = {
        //     videoId: youtubeItem.id.videoId,
        //     title: youtubeItem.snippet.title
        //   };
        //   items.push(item);
        //   state.items.push(item);
        // }
        // jobResults.value += youtubeData.items.length;

        //console.log('nextPageToken', youtubeData.nextPageToken);

        // If it's the same token from last time, then abort
        if (youtubeData.nextPageToken == searchNextPageToken) {
          return context.complete();
        }
        searchNextPageToken = youtubeData.nextPageToken;
        // let videoIds: string[] = youtubeData.items.map((item: any) => {
        //   return item.id.videoId as string;
        // });
        

        context.runStep(() => {
          if (enableGetPlaylists.value) {
            let playlistIds: string[] = youtubeData.items.map((youtubeItem: any) => {
              return youtubeItem.id as string;
            });
            context.complete();
          } else {
            let videoIds: string[] = youtubeData.items.map((youtubeItem: any) => {
              return youtubeItem.id.videoId as string;
            });
            videos(videoIds);
          }
        });
        //context.complete();

      }

      let videos = async(videoIds: string[], onComplete?: () => void) => {
        let data = {query: 'videos', videoIds: videoIds, dummyData: dummyData.value, includeChannel: searchParams.channelId == ''};

        // context.runStep(() => {
        //   console.log('videoIds', videoIds);
        //   if (onComplete) {
        //     onComplete();
        //   }
        // });
        // return;

        
        let resp: any;
        try {
          resp = await axios(`${backendUrl}/job`, {withCredentials: false, method: 'POST', data: data});
        } catch (err: any) {
          context.pause();
          errorMessage.value = 'Error Message:<br>' + err.response.data.message + '<br>' + err.response.data.stack;
          return alert('Error encountered on backend. Aborting query.');
        }
        let youtubeData = resp.data.data;
        //console.log('youtubeData');
        //console.log(youtubeData);
        //context.commit('fetchItem', resp.data);
        //console.log(resp.data);
        //state.items = state.items.concat(resp.data.items);
        let detailsMap = new Map();
        youtubeData.items.forEach((item: any) => {
          detailsMap.set(item.id, item);
        });
        // items.forEach(item => {
        //   if (detailsMap.has(item.videoId)) {
        //     let youtubeItem = detailsMap.get(item.videoId);
        //     item.description = youtubeItem.snippet.description;
        //     item.publishedAt = youtubeItem.snippet.publishedAt;
        //     item.duration = youtubeItem.contentDetails.duration;
        //     item.viewCount = youtubeItem.statistics.viewCount;
        //     item.likeCount = youtubeItem.statistics.likeCount;
        //   }
        // });
        // state.items.forEach(item => {
        //   if (detailsMap.has(item.videoId)) {
        //     let youtubeItem = detailsMap.get(item.videoId);
        //     item.description = youtubeItem.snippet.description;
        //     item.publishedAt = youtubeItem.snippet.publishedAt;
        //     item.duration = youtubeItem.contentDetails.duration;
        //     item.viewCount = youtubeItem.statistics.viewCount;
        //     item.likeCount = youtubeItem.statistics.likeCount;
        //   }
        // });
        // state.items = items;


        let testing = false;
        for (let i = 0; i < youtubeData.items.length; i++) {
          let youtubeItem = youtubeData.items[i];
          let item: any = {
            id: youtubeItem.id,
            title: youtubeItem.snippet.title
          };
          item.videoUrl = 'https://youtube.com/watch?v=' + item.id;
          item.description = youtubeItem.snippet.description;
          item.published = youtubeItem.snippet.publishedAt;
          //item.channelId = youtubeItem.snippet.channelId;
          
          if (youtubeItem.snippet.channelId) {

            item.channelUrl = 'https://youtube.com/channel/' + youtubeItem.snippet.channelId;
          }
          if (youtubeItem.snippet.channelTitle) {

            item.channelTitle = youtubeItem.snippet.channelTitle;
          }
          item.duration = convertISO8601ToReadable(youtubeItem.contentDetails.duration);
          item.views = youtubeItem.statistics.viewCount;
          item.likes = youtubeItem.statistics.likeCount;
          item.thumbnail = youtubeItem.snippet.thumbnails.medium || youtubeItem.snippet.thumbnails.default || {url: ''};
         
          for (let j = 0; j < (testing ? 1000 : 1); j++) {
            items.push(item);
            filteredItems.push(item);
            if (state.items.length < 200) {
              state.items.push(item);
            }
          }
        }
        jobResults.value = items.length;
        //console.log('items.length', items.length, youtubeData.items.length);

        // state.items.forEach(item => {
        //   if (detailsMap.has(item.id.videoId)) {
        //     let item2 = detailsMap.get(item.id.videoId);
        //     // console.log('item:');
        //     // console.log(item);
        //     // console.log('item2');
        //     // console.log(item2);
        //     item.statistics = item2.statistics;
        //     item.contentDetails = item2.contentDetails;
        //     item.snippet.publishedAt = item2.snippet.publishedAt;
        //     item.snippet.description = item2.snippet.description;
        //   }
        // });

        context.runStep(() => {
          if (videoId.value) {
            if (onComplete) {
              onComplete();
            }
          }
          else if (searchFeed.value) {
            
            return context.complete();

          } else {
            // Continue searching the next page of search results
            search();
          }
        });
      }


      let playlists = async(playlistIds: string[]) => {
        let data = {query: 'playlistItems', videoIds: playlistIds, dummyData: dummyData.value, includeChannel: searchParams.channelId == ''};
        let resp: any;
        try {
          resp = await axios(`${backendUrl}/job`, {withCredentials: false, method: 'POST', data: data});
        } catch (err: any) {
          context.pause();
          errorMessage.value = 'Error Message:<br>' + err.response.data.message + '<br>' + err.response.data.stack;
          return alert('Error encountered on backend. Aborting query.');
        }
        let youtubeData = resp.data.data;
        //console.log('youtubeData');
        //console.log(youtubeData);
        //context.commit('fetchItem', resp.data);
        //console.log(resp.data);
        //state.items = state.items.concat(resp.data.items);
        let detailsMap = new Map();
        youtubeData.items.forEach((item: any) => {
          detailsMap.set(item.id, item);
        });

        let testing = false;
        for (let i = 0; i < youtubeData.items.length; i++) {
          let youtubeItem = youtubeData.items[i];
          let item: any = {
            id: youtubeItem.id,
            title: youtubeItem.snippet.title
          };
          item.videoUrl = 'https://youtube.com/watch?v=' + item.id;
          item.description = youtubeItem.snippet.description;
          item.published = youtubeItem.snippet.publishedAt;
          //item.channelId = youtubeItem.snippet.channelId;
          
          if (youtubeItem.snippet.channelId) {

            item.channelUrl = 'https://youtube.com/channel/' + youtubeItem.snippet.channelId;
          }
          if (youtubeItem.snippet.channelTitle) {

            item.channelTitle = youtubeItem.snippet.channelTitle;
          }
          item.duration = convertISO8601ToReadable(youtubeItem.contentDetails.duration);
          item.views = youtubeItem.statistics.viewCount;
          item.likes = youtubeItem.statistics.likeCount;
          item.thumbnail = youtubeItem.snippet.thumbnails.medium || youtubeItem.snippet.thumbnails.default || {url: ''};
         
          for (let j = 0; j < (testing ? 1000 : 1); j++) {
            items.push(item);
            filteredItems.push(item);
            if (state.items.length < 200) {
              state.items.push(item);
            }
          }
        }
        jobResults.value = items.length;
        //console.log('items.length', items.length, youtubeData.items.length);

        // state.items.forEach(item => {
        //   if (detailsMap.has(item.id.videoId)) {
        //     let item2 = detailsMap.get(item.id.videoId);
        //     // console.log('item:');
        //     // console.log(item);
        //     // console.log('item2');
        //     // console.log(item2);
        //     item.statistics = item2.statistics;
        //     item.contentDetails = item2.contentDetails;
        //     item.snippet.publishedAt = item2.snippet.publishedAt;
        //     item.snippet.description = item2.snippet.description;
        //   }
        // });



        context.runStep(() => {
          search();
        });
      }

      
      let fromFeed = async () => {

        // let xmlUrl = 'https://www.youtube.com/feeds/videos.xml?channel_id=UCZ8_wntNdHu_VVJUItyupqA';

        // let resp: any;
        // try {
        //   resp = await axios(xmlUrl, {withCredentials: false, method: 'GET'});
        // } catch (err: any) {
        //   context.pause();
        //   errorMessage.value = 'Error Message:<br>' + err.response.data.message + '<br>' + err.response.data.stack;
        //   return alert('Error encountered on backend. Aborting query.');
        // }
        // let body = resp.data;
        
        let body = feedXml;
        //console.log(body);
        //body = '<a>asdf</a>'

        const parser = new DOMParser();
        const doc = parser.parseFromString(body, "text/xml");
        // print the name of the root element or error message
        const errorNode = doc.querySelector("parsererror");
        if (errorNode) {
          console.log("error while parsing");
        } else {
          //console.log(doc.documentElement.nodeName);
        }

        // console.log(doc.querySelectorAll('entry'));


        let size = doc.querySelectorAll('entry').length;
        let videoIds: string[] = [];
        for (let i = 0; i < size; i++) {

          let item = doc.querySelectorAll('entry').item(i);
          let videoId = item.getElementsByTagName('yt:videoId');
          // console.log(item.querySelector('yt\:videoId'));
          videoIds.push(videoId.item(0)?.textContent as string);
        }

        context.runStep(() => {
          
          videos(videoIds);
          
        });

      }


      async function nextVideos(videoIds: string) {

        videoIds = videoIds.replace(/[ ]+/g, '');
        videoIds = videoIds.replace(/^\s*$/g, '');
        let videoIdsArr: string[] = [];
        if (videoIds.indexOf(',') > -1) {
          videoIdsArr = videoIds.split(',');
        } else {
          videoIdsArr = videoIds.split('\n');
        }
        videoIdsArr = videoIdsArr.filter(element => {
          element = element.trim();
          if (element.length == 0) {
            return false;
          } else {
            return true;
          }
        });

        console.log(videoIdsArr);

        let parts: string[][] = [];
        while (true) {
          let part = videoIdsArr.splice(0, 50);
          if (part.length == 0) {
            break;
          }
          parts.push(part);

          await new Promise<void>((resolve) => {
            videos(part, () => {
              resolve();
            });
          });
        }

        context.complete();

        
      }


      context.onStart(() => {
        if (videoId.value) {
          nextVideos(videoId.value as string);
        }
        else if (searchFeed.value) {
          fromFeed();
        } else {

          search(true);
        }
      });
    });


    job.onComplete(() => {
      queryButton.value = 'Query';
      //alert('done');
    });

    job.onAbort(() => {
      queryButton.value = 'Query';
    });
    job.onActiveChange = () => {
      isLoading.value = job.active;
      if (job.active) {
        downloadButton.value = 'Generate CSV';
      }
    }

    function downloadBlob(blob: Blob, name = 'file.txt') {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', { 
          bubbles: true, 
          cancelable: true, 
          view: window 
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    }
    var csvBlob: Blob | null = null;
    var worker = new Worker('/csv-worker.js');
    let csv = async () => {
      if (downloadButton.value == 'Save CSV') {
        
        downloadBlob(csvBlob!, 'youtube-data.csv');
        return;
      }
      if (downloadButton.value == 'Please wait...') {
        return;
      }

      downloadButton.value = 'Please wait...';
      worker.onmessage = (event: MessageEvent) => {
        let blob = event.data as Blob;
        csvBlob = blob;
        downloadButton.value = 'Save CSV';
      };
      // worker.postMessage(items);
      worker.postMessage(filteredItems);
    }

    return {
      state,
      title,
      query,
      channelId,
      videoId,
      searchType,
      maxResults,
      date,
      beforeDate,
      queryButton,
      downloadButton,
      jobResults,
      dummyData,
      mlpShorts,
      searchShortsOnly,
      isLoading,
      errorMessage,
      showFilter,
      videoLength,
      filteredItemsTotal,
      showChannelColumns,
      enableGetPlaylists,
      searchFeed,
      csv,
      applyFilter() {
        if (!videoLength.value.match(/\d+\:\d\d\:\d\d$/g)) {
          return alert('Video Length must have this format: 00:00:00');
        }
        filteredItems = items.filter(item => {
          if (item.duration > videoLength.value) {
            return true;
          }
          return false;
        });
        filteredItemsTotal.value = filteredItems.length;
        console.log('filteredItems.length', filteredItemsTotal.value);


        downloadButton.value = 'Generate CSV';
        state.items = filteredItems.slice(0, 200);
      },
      toggleFilter() {
        showFilter.value = !showFilter.value;
      },
      abortQuery() {
        job.abort();
      },
      clear() {
        items = [];
        filteredItems = [];
        state.items = [];
        filteredItemsTotal.value = -1;
        jobResults.value = 0;
      },
      startJob() {
        if (job.active) {
          queryButton.value = 'Resume';
          job.pause();
          return;
        }
        if (job.paused) {
          queryButton.value = 'Pause';
          errorMessage.value = '';
          job.start();
          return;
        }
        if (items.length > 0) {
          return alert('Must clear results before running new query');
        }
        if (isNaN(maxResults.value)) {
          alert('Must enter a number for Maximum Results');
        }

        // if (searchType.value == 'q') {
        //   if (query.value) {
        //     searchParams.q = query.value;
        //   } else {
        //     //searchParams.q = title.value ? `allintitle:${title.value}` : null;
        //   }
        // } else if (searchType.value == 'channelId') {
        //   if (channelId.value) {
        //     searchParams.channelId = channelId.value;
        //   } else {
        //     //searchParams.q = title.value ? `allintitle:${title.value}` : null;
        //   }
        // }
        searchMLPShorts = mlpShorts.value;
        searchShorts = searchShortsOnly.value;
        
        searchParams.q = query.value;
        searchParams.channelId = channelId.value;
        
        if (channelId.value) {
          searchParams.channelId = channelId.value;
          showChannelColumns.value = false;
        } else {
          showChannelColumns.value = true;
        }
        searchParams.beforeDate = beforeDate.value;
        jobResults.value = 0;
        searchNextPageToken = '';
        
        job.start();
        queryButton.value = 'Pause';
        errorMessage.value = '';
      },
      fetch() {
        //alert(title.value);
        //alert('fetch');
        axios(`${backendUrl}?title=${encodeURIComponent(title.value)}&publishedAfter=${date}&publishedBefore=${beforeDate}`, {withCredentials: false, method: 'GET'}).then(resp => {
          //context.commit('fetchItem', resp.data);
          //console.log(resp.data);
          state.items = resp.data;
        });
      }
    }
  }
}
