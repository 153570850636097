

export class Jobs {

  context: JobContext;
  _onComplete?: () => void;
  _onAbort?: () => void;
  _started = false;
  _currentCb?: () => void;
  _paused = false;
  onActiveChange?: () => void;
  constructor() {
    this.context = new JobContext(this);
  }
  static create(cb: (context: JobContext) => void) {
    let job = new Jobs();
    cb(job.context);
    return job;
  }

  get active() {
    return this._started && !this._paused;
  }

  get paused() {
    return this._paused;
  }
  start() {
    if (this._paused) {
      this._paused = false;
      if (this.onActiveChange) {
        this.onActiveChange();
      }
      if (this._currentCb) {
        this._currentCb();
      }
      return;
    }
    if (this._started) {
      return false;
    }
    this._started = true;
    this.context._onStart!();
    if (this.onActiveChange) {
      this.onActiveChange();
    }
    return true;
  }
  pause() {
    this._paused = true;
    if (this.onActiveChange) {
      this.onActiveChange();
    }
  }
  onComplete(cb: () => void) {
    this._onComplete = cb;
  }
  onAbort(cb: () => void) {
    this._onAbort = cb;
  }
  complete() {
    this._started = false;
    if (this.onActiveChange) {
      this.onActiveChange();
    }
    if (this._onComplete) {
      this._onComplete();
    }
  }
  abort() {
    this._started = false;
    this._paused = false;
    if (this.onActiveChange) {
      this.onActiveChange();
    }
    if (this._onAbort) {
      this._onAbort();
    }
  }
}

class JobContext {
  job: Jobs;

  constructor(job: Jobs) {
    this.job = job;
  }
  _onStart?: () => void;
  onStart(cb: () => void) {
    this._onStart = cb;
  }
  
  runStep(cb: () => void) {
    this.job._currentCb = cb;
    if (!this.job.active) {
      return;
    }
    cb();
  }
  abort() {
    this.job.abort();
  }
  complete() {
    this.job.complete();
  }
  pause() {
    this.job.pause();
  }
}

